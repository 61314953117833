import React from "react"
import { ThemeProvider } from "styled-components"
import { Modal } from "@redq/reuse-modal"
import { theme } from "common/theme/appModern"
import { ResetCSS } from "common/assets/css/style"
import Sticky from "react-stickynode"
import Navbar from "containers/AppModern/NavbarAr"
import Banner from "containers/AppModern/BannerAr"
import AppSlider from "containers/AppModern/AppSliderAr"
import Features from "containers/AppModern/FeaturesAr"
import DashboardFeatures from "containers/AppModern/Dashboard"
import ProductSlide from "containers/AppModern/ProductSlideAr"
import DesignedAndBuilt from "containers/AppModern/DesignedAndBuilt"
import PricingPolicy from "containers/AppModern/PricingPolicy"
import TeamPortfolio from "containers/AppModern/TeamPortfoilo"
import Testimonial from "containers/AppModern/Testimonial"
import Newsletter from "containers/AppModern/Newsletter"
import Footer from "containers/AppModern/FooterAr"
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/AppModern/appModern.style"
import "@redq/reuse-modal/es/index.css"

import Seo from "components/seo"

const AppModern = () => {
  const lang = "ar"

  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Dayra" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar lang={lang} />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <Features />
            <AppSlider />
            {/* <DashboardFeatures />*/}
            <ProductSlide />
            {/* <Testimonial /> */}
            {/* <DesignedAndBuilt /> */}
            {/* <PricingPolicy /> */}
            <TeamPortfolio />
            <Newsletter />
          </ContentWrapper>
          <Footer lang={lang} />
        </AppWrapper>
      </>
    </ThemeProvider>
  )
}
export default AppModern
