import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import ScrollSpyMenu from "common/components/ScrollSpyMenu"
import Scrollspy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Icon } from "react-icons-kit"
import { menu } from "react-icons-kit/feather/menu"
import { x } from "react-icons-kit/feather/x"
import { search } from "react-icons-kit/feather/search"
import Logo from "common/components/UIElements/Logo"
import Button from "common/components/Button"
import Container from "common/components/UI/Container"
import useOnClickOutside from "common/hooks/useOnClickOutside"
import NavbarWrapper, { MenuArea, MobileMenu, Search } from "./navbar.style"
import LogoImage from "common/assets/image/appModern/logo-white.png"
import LogoImageAlt from "common/assets/image/appModern/logo.png"
import logomob from "common/assets/image/appModern/dayra_logo_image.png"
import { isMobile } from "react-device-detect"
import { useMediaQuery } from "react-responsive"
import english from "../../../common/assets/image/appModern/OIP (2).jpeg"
import arabic from "../../../common/assets/image/appModern/Flag_of_Egypt.jpg"
import PropTypes from "prop-types"
import { right } from "styled-system"
const Navbar = ({ lang }) => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenuAr {
            id
            label
            path
            staticLink
            offset
          }
        }
      }
    }
  `)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  })
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const { navMenuAr } = data.appModernJson.navbar
  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  })

  const searchRef = useRef(null)
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  )

  const toggleHandler = (type) => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      })
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      })
    }
  }

  const handleOnChange = (event) => {
    setState({
      ...state,
      search: event.target.value,
    })
  }

  const handleSearchForm = (event) => {
    event.preventDefault()

    if (state.search !== "") {
      console.log("search data: ", state.search)

      setState({
        ...state,
        search: "",
      })
    } else {
      console.log("Please fill this field.")
    }
  }

  const scrollItems = []

  navMenuAr.forEach((item) => {
    scrollItems.push(item.path.slice(1))
  })

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    })
  }

  return (
    <NavbarWrapper className="navbar">
      <Container dir="rtl" lang="ar">
        <Logo
          style={{ width: "170px" }}
          href="/appmodern"
          logoSrc={LogoImageAlt}
          title="App Modern"
          className="main-logo"
        />

        <Logo
          href="/appmodern"
          style={{ width: "170px" }}
          logoSrc={LogoImageAlt}
          title="App Modern"
          className="logo-alt"
        />
        {/* end of logo */}
        {console.log(navMenuAr)}
        <MenuArea
          className={state.searchToggle ? "active" : ""}

          // style={{ marginLeft: "500px" }}
        >
          <ScrollSpyMenu
            className="menu"
            menuItems={navMenuAr}
            dir="rtl"
            lang="ar"
            offset={-84}
          />

          <div
            className="mohsen"
            style={{ float: "right", marginLeft: "50px" }}
          >
            {lang == "ar" && (
              <a href="/">
                <img
                  src={english}
                  style={{
                    width: "2.5vw",
                    height: "4vh",
                    borderRadius: "50%",
                  }}
                ></img>
              </a>
            )}
            {lang == "en" && (
              <a href="/appmodernAr">
                <img
                  src={arabic}
                  style={{
                    width: "2.5vw",
                    height: "4vh",
                    borderRadius: "50%",
                  }}
                ></img>
              </a>
            )}
          </div>

          {/* end of main menu */}

          {/* <Search className="search" ref={searchRef}>
            <form onSubmit={handleSearchForm}>
              <input
                type="text"
                value={state.search}
                placeholder="Enter your keyword"
                onChange={handleOnChange}
              />
            </form>
            <Button
              className="text"
              variant="textButton"
              icon={
                <Icon
                  icon={state.searchToggle ? x : search}
                  style={{ color: "#f6c04d" }}
                />
              }
              onClick={() => toggleHandler("search")}
            />
          </Search> */}
          {/* end of search */}

          {/* <AnchorLink href="#trail" offset={84}>
            <Button
              className="trail"
              title="Try for Free"
              style={{ color: "black" }}
            />
          </AnchorLink> */}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} style={{ color: "#f6c04d" }} />
              ) : (
                <Fade>
                  <Icon
                    className="close"
                    icon={menu}
                    style={{ color: "#f6c04d" }}
                  />
                </Fade>
              )
            }
            color="#f6c04d"
            variant="textButton"
            onClick={() => toggleHandler("menu")}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? "active" : ""}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
            {navMenuAr.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <a href={menu.path}>{menu.label}</a>
              </li>
            ))}
            <>
              {lang == "ar" && (
                <a href="/">
                  <img
                    src={english}
                    style={{
                      width: "10vw",
                      height: "6vh",
                      borderRadius: "50%",
                    }}
                  ></img>
                </a>
              )}
              {lang == "en" && (
                <a href="/appmodernAr">
                  <img
                    src={arabic}
                    style={{
                      width: "10vw",
                      height: "6vh",
                      borderRadius: "50%",
                    }}
                  ></img>
                </a>
              )}
            </>
          </Scrollspy>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  )
}
Navbar.propTypes = {
  lang: PropTypes.object,
}
export default Navbar
